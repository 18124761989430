<template>
    <Carousel />
    <div class="text-center py-5">
        <p class="leading-7">SAUTER,只使用德国生产的零部件<br>每个核心步骤皆由德国钢琴制造大师手工精心打造</p>
        <div class="flex  justify-center">
            <img class="object-cover pt-3 max-w-40" src="/images/madeinGM.png">
        </div>
        <div class="pt-10">
            <a href="/history"
                class="bg-red-600 rounded-full px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">探索首德</a>
        </div>
    </div>
    <div class="container mx-auto py-5 max-w-[1300px]">
        <div class="mb-10" alt="探索">
            <p class="tracking-wider text-2xl py-5 text-center">德国首德，始于1819</p>
            <p class="text-center text-sm lg:text-base text-gray-500/90 content-justify leading-7">
                自1819年在德国施派欣根问世以来<br>200多年的历史耀眼夺目，是欧洲制琴史上一颗璀璨明珠<br>
                SAUTER家族传递完全德国制造的承诺<br>在漫漫历史长河中久经考验，不断诉说着传奇

            </p>
        </div>
        <div class="flex justify-center">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mx-5 lg:mx-0">
                <div class="relative overflow-hidden h-full hover:-translate-y-1 duration-150 hover:opacity-85">
                    <a href="/pianos/sauter/grand/59">
                        <img class="object-cover aspect-square saturate-100" src="/images/detail2.webp">
                        <div class="absolute z-10 bottom-0 w-full ">
                            <div class="flex justify-center items-center bg-gray-500/70">
                                <p class="p-4 text-lg text-white tracking-wider">SAUTER 首德钢琴</p>
                            </div>
                        </div>
                    </a>
                </div>

                <div class="relative overflow-hidden h-full hover:-translate-y-1 duration-150 hover:opacity-85">
                    <a href="/pianos/design/grand/61">
                        <img class="object-cover aspect-square saturate-100" src="/images/detail8.jpg">
                        <div class="absolute z-10 bottom-0 w-full ">
                            <div class="flex justify-center items-center bg-gray-800/50">
                                <p class="p-4 text-lg text-white tracking-wider">Peter Maly设计</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="relative overflow-hidden h-full hover:-translate-y-1 duration-150 hover:opacity-85">
                    <a href="/pianos/carl/se/39">
                        <img class="object-cover aspect-square saturate-100" src="/images/detail7.jpg">
                        <div class="absolute z-10 bottom-0 w-full ">
                            <div class="flex justify-center items-center bg-gray-800/50">
                                <p class="p-4 text-lg text-white tracking-wider">CARL JOHANN 卡尔·约翰钢琴</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="relative overflow-hidden h-full hover:-translate-y-1 duration-150 hover:opacity-85">
                    <a href="/sound">
                        <img class="object-cover aspect-square saturate-100" src="/images/detail1.webp">
                        <div class="absolute z-10 bottom-0 w-full mx-auto">
                            <div class="flex justify-center items-center bg-gray-800/50 text-center">
                                <p class="p-4 text-lg text-white tracking-wider">首德之音的秘密</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="relative overflow-hidden h-full hover:-translate-y-1 duration-150 hover:opacity-85">
                    <a href="/explorer">
                        <img class="object-cover aspect-square saturate-100" src="/images/detail4.webp">
                        <div class="absolute z-10 bottom-0 w-full ">
                            <div class="flex justify-center items-center bg-gray-800/50">
                                <p class="p-4 text-lg text-white tracking-wider">完全德国制造的选购指南</p>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="relative overflow-hidden h-full hover:-translate-y-1 duration-150 hover:opacity-85">
                    <a href="/history">
                        <img class="object-cover aspect-square saturate-100" src="/images/detail6.webp">
                        <div class="absolute z-10 bottom-0 w-full ">
                            <div class="flex justify-center items-center bg-gray-800/50">
                                <p class="p-4 text-lg text-white tracking-wider">SAUTER(首德)钢琴史</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="py-6">
            <p class="font-bold tracking-wider text-2xl py-5 text-center">新琴速览</p>
            <Slider></Slider>
        </div>
        <div class="py-6">
            <p class="font-bold tracking-wider text-2xl py-5 text-center">新闻与活动</p>
            <div class="grid grid-cols-1 gap-6 mx-5 lg:mx-0 lg:grid-cols-4 lg:gap-6">
                <div v-for="item in news" :key="item.id">
                    <a class="relative" href="#">
                        <div class="hover:-translate-y-1 duration-150 hover:opacity-85">
                            <div class="border border-yellow-600/20 p-1">
                                <img class="object-cover w-auto saturate-150 lg:min-h-[190px]" :src="`${item.n_cover}`">
                                <div class="py-3 text-justify">
                                    <p class="text-sm font-bold max-h-[40px] overflow-hidden lg:min-h-[40px] ">{{ item.n_title }}</p>
                                    <p
                                        class="text-sm text-gray-500 pt-6 max-h-[64px] lg:min-h-[64px] lg:max-h-[64px] overflow-hidden">
                                        {{
                    item.n_brief }}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <Service></Service>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Carousel from '@/components/common/Carousel.vue';
import Slider from '@/components/common/Slider.vue';
import Service from '@/components/common/Service.vue';

const sauter = ref([]);
const carljohann = ref([]);
const news = ref([]);

const fetchData = async (url, num) => {
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data.slice(0, num);
};

const fetchProducts = async () => {
    try {
        sauter.value = await fetchData('https://api.acton-shanghai.cn/openRDS/api/product/list/15', 4);
        carljohann.value = await fetchData('https://api.acton-shanghai.cn/openRDS/api/product/list/16', 4);
        news.value = await fetchData('https://api.acton-shanghai.cn/openRDS/api/news/list/15', 8);
    } catch (error) {
        console.error('Error fetching products:', error);
    }
};

onMounted(() => {
    fetchProducts();
});
</script>

<script>
import Carousel from '@/components/common/Carousel.vue';
export default {
    components: { Carousel }
};
</script>

<style>
.badge {
    top: -10px;
}
</style>